import { GoogleMap, InfoWindow, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo } from "react";

export default function Maps() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCU-hAEN284trRtuD8-y41Jjzj1J4Lz-KU",
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map />;
}

function Map() {
  const center = useMemo(() => ({ lat: 57.721487196609054, lng: 12.941246932943946 }), []);
  const place = 
    {
       name: "Khói Restaurant",
       text: "Hello",
       position: {lat: 57.721487196609054,lng: 12.941246932943946}
    }
  
  return (
    <>
    <GoogleMap zoom={18} center={center} mapContainerClassName="map-container">
        <Marker
            key={`marker-${place.name}`}
            title={place.name}
            name={place.name}
            position={place.position}
        >
            <InfoWindow
                key={`infowindow-${place.name}`}
                visible={true}
                position={place.position}
                >
                <div>
                <br />
                <h5>Opening hours</h5>
                <p>Mon-Thurs: 11:00-20:00</p>
                <p>Fri: 11:00-21:00</p>
                <p>Sat: 12:00-21:00</p>
                <p>Sun: Closed</p>
                <h5>Address:</h5>
                <p> Torggatan 31, 50334 Borås </p>
                <h5>Email:</h5>
                <p>khoivietnamesecuisine.res@gmail.com</p>
                <h5>Phone number:</h5>
                <p>072-233 01 68</p>
                </div>
            </InfoWindow>
        </Marker>
    </GoogleMap>
    </>
  );
}